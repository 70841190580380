import React from 'react';
import { TrackingProvider } from '@volvo-cars/tracking';
import { useCurrentMarketSite } from '@vcc-www/market-sites';
import HydrateInView from '@vcc-www/rendering/HydrateInView';
import JSSPlaceholderRenderer from '../../JSSPlaceholderRenderer';
import { PageLayoutProps } from '../../PageLayoutProps';

const HighlightsPage: React.FC<PageLayoutProps> = ({
  header,
  placeholders,
}) => {
  const { marketName, locale, regionCode } = useCurrentMarketSite();
  return (
    <TrackingProvider
      market={marketName}
      locale={locale}
      trackPageLoad
      enableReactTracking={false}
      marketLanguage={locale}
      countryCode={regionCode}
    >
      {header}
      <main>
        <JSSPlaceholderRenderer
          renderings={placeholders['model-intro']}
          extend={{ zIndex: 999, position: 'relative' }}
        />
        <JSSPlaceholderRenderer
          renderings={placeholders['local-sub-menu']}
          withStickyChildren
        />
        <JSSPlaceholderRenderer renderings={placeholders['model-intro-2']} />
        <JSSPlaceholderRenderer renderings={placeholders['hero']} />
        <JSSPlaceholderRenderer renderings={placeholders['image-with-text']} />
        <HydrateInView>
          <JSSPlaceholderRenderer renderings={placeholders['icon-callouts']} />
          <JSSPlaceholderRenderer renderings={placeholders['hero-padded']} />
          <JSSPlaceholderRenderer renderings={placeholders['callouts']} />
        </HydrateInView>
        <HydrateInView>
          <JSSPlaceholderRenderer renderings={placeholders['timeline']} />
          <JSSPlaceholderRenderer
            renderings={placeholders['sustainability-hero']}
          />
          <JSSPlaceholderRenderer renderings={placeholders['callouts-2']} />
          <JSSPlaceholderRenderer renderings={placeholders['model-stats']} />
          <JSSPlaceholderRenderer renderings={placeholders['callouts-3']} />
        </HydrateInView>
        <JSSPlaceholderRenderer renderings={placeholders['client-carousel']} />
        <JSSPlaceholderRenderer
          renderings={placeholders['highlights-partner-logos']}
        />
        <HydrateInView>
          <JSSPlaceholderRenderer
            renderings={placeholders['image-with-text-2']}
          />
          <JSSPlaceholderRenderer
            renderings={placeholders['promotions-text-image']}
          />
        </HydrateInView>
        <JSSPlaceholderRenderer
          renderings={placeholders['product-list-carousel']}
        />
        <JSSPlaceholderRenderer
          renderings={placeholders['news-featured-articles']}
        />
      </main>
    </TrackingProvider>
  );
};

export default HighlightsPage;
